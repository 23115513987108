const advertiser = {
  state: {
    pageType: 'customer',
    beginUpdate: new Date(),
    detailId: null
  },
  mutations: {
    set_advertiser_pageType (state, data) {
      state.pageType = data
    },
    set_advertiser_update (state, data) {
      state.beginUpdate = data
    },
    set_advertiser_detailId (state, data) {
      state.detailId = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default advertiser
