<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent" ></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import advertiserStoreModule from '@/store/modules/advertiser'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    salerOverviewComponent: () => import('@/components/overview/SalerOverview'),
    leftComponent: () => import('./base/Left'),
    bottomComponent: () => import('./base/Bottom')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('advertiser')) {
      this.$store.registerModule('advertiser', advertiserStoreModule)
    }
  },
  data () {
    return {
      closePersonInfo: this.$store.getters.token.publisherSetting.personInfo // 关闭个人能力
    }
  },
  mounted () {
    if (this.closePersonInfo) {
      this.setRightComponent('')
      this.setShowRightBlock(false)
    } else {
      this.setRightComponent('salerOverviewComponent')
    }
    this.setLeftComponent('leftComponent')
    this.setBottomComponent('bottomComponent')
    this.setBottomHeight('90%')
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.unregisterModule('advertiser')
  }

}
</script>
